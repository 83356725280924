import { AvailBoundObject, AvailChannel, AvailData } from './types'
import fetch from 'unfetch'
import download from 'downloadjs'

declare var CHANNEL_URL: string | undefined // Injected by the build process.

let isBound: boolean | Promise<void> = false
const bindObject = async () => {
  if (isBound) return isBound
  if (window.CefSharp) {
    isBound = window.CefSharp.BindObjectAsync('availBoundObject')
  }
  return isBound
}

const getMockedBoundObject = () => {
  console.warn('using mocked bound AVAIL object')
  return {
    getAuthorizationToken: async () => 'authorization',
    downloadTriggered: async (data: string) =>
      console.info('download triggered', data)
  }
}

const getAvail = async (): Promise<AvailBoundObject> => {
  await bindObject()
  if (!window.availBoundObject) {
    // return getMockedBoundObject()
    throw new Error('AvailBoundObject could not be found.')
  }
  return window.availBoundObject
}

export const getAuthorizationToken = async (): Promise<string> => {
  const avail = await getAvail()
  return avail.getAuthorizationToken()
}

export const getChannels = async (
  authToken: string
): Promise<AvailChannel[]> => {
  let channelUrl: string | undefined
  if (typeof process !== 'undefined' && process.env.REACT_APP_CHANNEL_URL) {
    channelUrl = process.env.REACT_APP_CHANNEL_URL
  } else if (typeof CHANNEL_URL !== 'undefined') {
    channelUrl = CHANNEL_URL
  }
  if (!channelUrl) {
    throw new Error('Cannot get channels. channelUrl is not defined.')
  }
  const response = await fetch(channelUrl, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${authToken}`
    }
  })
  if (!response.ok) {
    throw new Error(`Error fetching channels: ${await response.text()}`)
  }
  return response.json()
}

export const downloadFile = async (filename: string): Promise<void> => {
  console.info('triggering download', filename)
  return new Promise(resolve => {
    const ajax = download(filename) as XMLHttpRequest
    const originalAjaxOnLoad = ajax.onload!.bind(ajax)
    ajax.onload = e => {
      originalAjaxOnLoad(e)
      resolve()
    }
  })
}

export interface PrepareDownloadOptions {
  shouldDownloadFile?: boolean
}
export const prepareDownload = async (
  data: AvailData,
  channelId: string,
  { shouldDownloadFile = false }: PrepareDownloadOptions = {}
): Promise<void> => {
  const avail = await getAvail()
  console.info('sending download info to avail')
  await avail.downloadTriggered(
    JSON.stringify({
      ...data,
      channelId
    })
  )
  if (shouldDownloadFile) {
    return downloadFile(data.downloadUrl).then(() => {
      return new Promise(resolve => {
        setTimeout(() => {
          // Wait a little bit for the browser to recognize the download.
          console.info('posting close message')
          window.parent.postMessage('close', '*')
          resolve()
        }, 100)
      })
    })
  } else {
    // Post the close message now since we're not downloading the file.
    console.info('posting close message')
    window.parent.postMessage('close', '*')
  }
}
