import { DefaultTheme } from 'styled-components'

export const theme: DefaultTheme = {
  colors: {
    green: '#08A1A3',
    textColor: '#515151',
    white: '#FFF',
    gray4: '#D5D5D5',
    gray1: '#FAFAFA'
  }
}
