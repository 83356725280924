import React, { FC, useState } from 'react'
import styled from 'styled-components'
import { darken, lighten } from 'polished'
import { AvailChannel as IChannel, AvailData } from './types'
import { prepareDownload } from './avail'

interface ChannelProps {
  channel: IChannel
  className?: string
}

const getWindowData = (): AvailData => {
  const searchData = decodeURIComponent(
    window.location.search.replace('?data=', '')
  )
  console.info('parsing window data', searchData)
  return JSON.parse(searchData)
}

const ChannelC: FC<ChannelProps> = ({ className, channel }) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const sendToChannel = async () => {
    try {
      console.info('sending to channel', channel.Id, channel.Name)
      const data: AvailData = {
        ...getWindowData(),
        channelId: channel.Id
      }
      console.info(data)
      console.info('posting set default channel message')
      window.parent.postMessage(JSON.stringify(channel), '*')
      await prepareDownload(data, channel.Id, { shouldDownloadFile: true })
    } catch (err) {
      setError(true)
      console.error('Error adding to channel')
      console.error(err)
    }
  }
  return (
    <a
      href={'#'}
      className={`${className} ${loading ? 'loading' : ''} ${
        error ? 'error' : ''
      }`}
      onClick={e => {
        e.preventDefault()
        setLoading(true)
        setError(false)
        sendToChannel().then(() => setLoading(false))
      }}
    >
      <div>
        <span>{channel.Name}</span>
        <div>Add &rarr;</div>
      </div>
    </a>
  )
}

export const Channel = styled(ChannelC)`
  text-decoration: none;
  color: ${props => props.theme.colors.textColor};
  transition: color 0.1s linear;
  &.loading {
    opacity: 0.5;
    background: ${props => props.theme.colors.gray4};
    color: ${props => props.theme.colors.textColor};
  }
  > div {
    padding: 5px 0;
    display: flex;
    align-items: center;
  }
  span {
    margin-right: 10px;
  }
  > div > div {
    display: block;
    border-radius: 2px;
    margin-left: auto;
    background: ${props => props.theme.colors.green};
    color: ${props => props.theme.colors.white};
    min-width: 75px;
    text-align: center;
    text-decoration: none;
    padding: 5px;
    font-size: 80%;
    transition: background 0.1s linear;
  }
  &:hover {
    color: ${props => darken(0.5, props.theme.colors.textColor)};
    > div > div {
      background: ${props => lighten(0.03, props.theme.colors.green)};
    }
  }
`
