import styled, { keyframes } from 'styled-components'

const rotate = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`

export const Spinner = styled.div`
  display: inline-block;
  padding: 0;
  margin: 10px;
  width: 14px;
  height: 14px;
  border: solid 2px ${props => props.theme.colors.green};
  border-top-color: transparent;
  border-radius: 50%;
  animation: ${rotate} 1s linear infinite;
`
