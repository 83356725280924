import React, { FC, useEffect, useState } from 'react'
import { getAuthorizationToken } from './avail'
import { Spinner } from './Spinner'

export const AuthContext = React.createContext<string | null>(null)

export const AuthProvider: FC<{}> = ({ children }) => {
  const [authToken, setAuthToken] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    ;(async () => {
      setLoading(true)
      setAuthToken(await getAuthorizationToken())
      setLoading(false)
    })()
  }, [])

  if (loading) return <Spinner />
  return <AuthContext.Provider value={authToken} children={children} />
}
