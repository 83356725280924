import React, { FC, useContext, useEffect, useState } from 'react'
import { Channel } from './Channel'
import { getChannels } from './avail'
import { AuthContext } from './AuthContext'
import { AvailChannel } from './types'
import { Spinner } from './Spinner'
import styled from 'styled-components'

export const ChannelList: FC<{}> = styled(({ className }) => {
  const [channels, setChannels] = useState<AvailChannel[]>([])
  const [isExpanded, setIsExpanded] = useState<boolean>(false)
  const [loading, setLoading] = useState(false)
  const authToken = useContext(AuthContext)
  useEffect(() => {
    if (authToken) {
      ;(async () => {
        setLoading(true)
        setChannels(await getChannels(authToken))
        setLoading(false)
      })()
    }
  }, [authToken])

  if (loading) return <Spinner />

  const allChannels = isExpanded ? channels : channels.slice(0, 5)
  return (
    <div className={className}>
      {allChannels.map(channel => (
        <Channel channel={channel} key={channel.Id} />
      ))}
      {!isExpanded && channels.length > 5 && (
        <a className={'expand'} onClick={() => setIsExpanded(true)}>
          Show More Channels
        </a>
      )}
    </div>
  )
})`
  > .expand {
    display: block;
    text-align: center;
    margin-top: 10px;
    color: ${props => props.theme.colors.green};
    cursor: pointer;
  }
`
