import React, { FC } from 'react'
import styled, { ThemeProvider } from 'styled-components'
import { ChannelList } from './ChannelList'
import { theme } from './theme'
import logo from './avail-logo.png'
import { AuthProvider } from './AuthContext'

interface AppProps {
  className?: string
}

const App: FC<AppProps> = ({ className }) => {
  return (
    <ThemeProvider theme={theme}>
      <div className={className}>
        <div className={'content'}>
          <AuthProvider>
            <ChannelList />
          </AuthProvider>
        </div>
        <div className={'footer'}>
          <img src={logo} alt={'AVAIL'} />
        </div>
      </div>
    </ThemeProvider>
  )
}

export default styled(App)`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  flex-direction: column;
  > .content {
    padding: 15px 20px;
    overflow-y: auto;
    flex-grow: 1;
  }
  > .footer {
    display: flex;
    justify-content: flex-end;
    padding: 10px 20px;
    border-top: solid 1px ${props => theme.colors.gray4};
    background: ${props => theme.colors.gray1};
    > img {
      height: 8px;
    }
  }
`
